<template>
  <div class="px-lg-5 px-md-3 container-fluid">
    <div class="card border-0 shadow p-5 my-4">
      <panel />
    </div>
  </div>
</template>

<script lang="js">
import panel from '../components/control-panel.vue';

export default {
  name: '',
  components: {
    panel,
  },
};
</script>
